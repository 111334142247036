const plexPlatformMap = {
  android: 'android',
  'apple tv': 'atv',
  chrome: 'chrome',
  chromecast: 'chromecast',
  dlna: 'dlna',
  firefox: 'firefox',
  'internet explorer': 'ie',
  ios: 'ios',
  ipad: 'ios',
  iphone: 'ios',
  kodi: 'kodi',
  linux: 'linux',
  nexus: 'android',
  macos: 'macos',
  'microsoft edge': 'msedge',
  opera: 'opera',
  osx: 'macos',
  playstation: 'playstation',
  'plex home theater': 'plex',
  'plex media player': 'plex',
  plexamp: 'plexamp',
  plextogether: 'synclounge',
  roku: 'roku',
  safari: 'safari',
  samsung: 'samsung',
  synclounge: 'synclounge',
  tivo: 'tivo',
  tizen: 'samsung',
  tvos: 'atv',
  vizio: 'opera',
  wiiu: 'wiiu',
  windows: 'windows',
  'windows phone': 'wp',
  xbmc: 'xbmc',
  xbox: 'xbox',
};

export default plexPlatformMap;
